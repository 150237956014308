<template>
    <div>
        <!-- S:Bread crumbs -->
        <v-row>
            <v-col md="12" sm="12" lg="12" class="pa-10 pb-1">
                <v-card elevation="1" class="containerbox">
                    <v-toolbar elevation="2" dense class="menu-header">
                        <v-app-bar-nav-icon text>
                            <v-icon color="secondary">mdi-home</v-icon>
                        </v-app-bar-nav-icon>
                        <v-toolbar-title class="pa-0">
                            <v-breadcrumbs>
                                <template>
                                    <v-breadcrumbs-item class="primarytext--text">
                                        Parent
                                    </v-breadcrumbs-item>
                                    <v-breadcrumbs-item small> / </v-breadcrumbs-item>
                                    <v-breadcrumbs-item disabled> Page Name </v-breadcrumbs-item>
                                </template>
                            </v-breadcrumbs>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col sm="6" md="6" lg="6" class="pa-0 ma-0 pt-6"> </v-col>
                            <v-col sm="3" md="3" lg="3" class="pa-0 ma-0 pt-6">
                                <!-- Button to add menu -->
                                <v-btn class="ma-2 btn-style float-right" elevation="1" outlined color="primarytext">
                                    <v-icon dark left> mdi-plus </v-icon>New Item
                                </v-btn>
                            </v-col>
                            <v-col sm="3" md="3" lg="3" class="pa-0 ma-0 pt-8">
                                <v-text-field class="table-search float-right mr-8" 
                                    v-model="searchResult" append-icon="mdi-magnify" label="Search" outlined
                                    clearable dense>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 pb-10">
                                <v-data-table :headers="headers" :items="listData" class="pa-5 ma-0 pt-0 containerbox"
                                    item-key="Name" :search="searchResult"></v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <br/>
    </div>    
</template>
  
<script>
export default {
    data() {
        return {            
            searchResult: [],            
            headers: [
                { text: 'Dessert (100g serving)', value: 'name', class: 'primary customwhite--text',},
                { text: 'Calories', value: 'calories', class: 'primary customwhite--text', },
                { text: 'Fat (g)', value: 'fat' , class: 'primary customwhite--text',},
                { text: 'Carbs (g)', value: 'carbs' , class: 'primary customwhite--text',},
                { text: 'Protein (g)', value: 'protein' , class: 'primary customwhite--text',},
                { text: 'Iron (%)', value: 'iron', class: 'primary customwhite--text', },                
            ],
            listData: [
                {
                    name: 'Frozen Yogurt',
                    calories: 159,
                    fat: 6.0,
                    carbs: 24,
                    protein: 4.0,
                    iron: '1%',
                },
                {
                    name: 'Ice cream sandwich',
                    calories: 237,
                    fat: 9.0,
                    carbs: 37,
                    protein: 4.3,
                    iron: '1%',
                },
                {
                    name: 'Eclair',
                    calories: 262,
                    fat: 16.0,
                    carbs: 23,
                    protein: 6.0,
                    iron: '7%',
                },
                {
                    name: 'Cupcake',
                    calories: 305,
                    fat: 3.7,
                    carbs: 67,
                    protein: 4.3,
                    iron: '8%',
                },
                {
                    name: 'Gingerbread',
                    calories: 356,
                    fat: 16.0,
                    carbs: 49,
                    protein: 3.9,
                    iron: '16%',
                },
                {
                    name: 'Jelly bean',
                    calories: 375,
                    fat: 0.0,
                    carbs: 94,
                    protein: 0.0,
                    iron: '0%',
                },
                {
                    name: 'Lollipop',
                    calories: 392,
                    fat: 0.2,
                    carbs: 98,
                    protein: 0,
                    iron: '2%',
                },
                {
                    name: 'Honeycomb',
                    calories: 408,
                    fat: 3.2,
                    carbs: 87,
                    protein: 6.5,
                    iron: '45%',
                },
                {
                    name: 'Donut',
                    calories: 452,
                    fat: 25.0,
                    carbs: 51,
                    protein: 4.9,
                    iron: '22%',
                },
                {
                    name: 'KitKat',
                    calories: 518,
                    fat: 26.0,
                    carbs: 65,
                    protein: 7,
                    iron: '6%',
                },
            ],
            childheaders: [
                { text: 'Dessert (100g serving)', align: 'start', sortable: false, value: 'name', },
                { text: 'Calories', value: 'calories' },
                { text: 'Fat (g)', value: 'fat' },
                { text: 'Carbs (g)', value: 'carbs' },
                { text: 'Protein (g)', value: 'protein' },
                { text: 'Iron (%)', value: 'iron' },
            ],
        }
    },
}
</script>